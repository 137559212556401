import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {color} from './Global/Colors'
import Close from './LBIcons/icons/Close'

const Wrapper = styled.div`
	width: 100%;
	background-color: rgb(243, 244, 244);
`

const Container = styled.div`
	padding: 0 16px;
	@media (min-width: 768px) {
		max-width: 768px;
		padding: 0 24px;
	}
	@media (min-width: 1024px) {
		max-width: 1024px;
		padding: 0 16px;
	}
	@media (min-width: 1440px) {
		max-width: 1440px;
		padding: 0 160px;
	}
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	& p {
		margin: 0;
		padding: 16px 0;
		color: ${color.espressoLight};
		font-size: 13px;
		font-weight: normal;
		letter-spacing: -0.1px;
		line-height: 20px;
	}
	& a {
		color: ${color.blue} !important;
		background: none;
	}
	& a:hover {
		color: ${color.blue} !important;
		text-decoration: underline;
		background: none;
	}
`

const CloseButton = styled.button`
	padding-left: 16px;
	margin-top: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${color.espressoMedium};
	background: transparent;
	cursor: pointer;
	border: none;
	-webkit-appearance: none;
`

const Banner = ({content}) => {
	const [dismissed, setDismissed] = useState(true)
	const dismissTopBannerKey = 'dismissTopBanner'

	useEffect(() => {
		const dismissVal = window.localStorage.getItem(dismissTopBannerKey)
		if (!dismissVal) {
			setDismissed(false)
		}
	}, [])

	const handleCloseClick = () => {
		window.localStorage.setItem(dismissTopBannerKey, '1')
		setDismissed(true)
	}

	useEffect(() => {
		window.dispatchEvent(new Event('resize')) // force resize event to trigger iframe height calculation
	}, [dismissed])

	if (dismissed) return null

	return (
		<Wrapper id="top-banner">
			<Container>
				<p>{content}</p>
				<CloseButton aria-label="close banner" onClick={handleCloseClick}>
					<Close role="presentation" size={12} />
				</CloseButton>
			</Container>
		</Wrapper>
	)
}

export default Banner
